#projects-around {
    height: 720px;
    padding: 0;
    position: relative;

    .text-overlay {
        position: absolute;
        top: 0;
        padding-left: 80px;
        height: 100%;
        width: 30%;
        z-index: 999;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
        );
        display: flex;
        justify-content: center;
        flex-direction: column;

        h3 {
            padding-bottom: 40px;
            color: var(--primary-2);
            font-weight: 300;
            font-size: 40px;
            line-height: 61px;
        }

        p {
            font-weight: normal;
            font-size: 18px;
            line-height: 145.7%;
        }

        .secondary-button {
            width: fit-content;
        }
    }

    .projects-map {
        width: 100%;

        .olControlZoom {
            display: none;
        }

        #mapViewerStatusTable {
            display: none !important;
        }
    }
}

@media (max-width: 1200px) {
    #projects-around {
        .text-overlay {
            padding: 0 20px;
            width: 50%;
        }
    }
}

@media (max-width: 652px) {
    #projects-around {
        h3 {
            font-size: 28px !important;
            padding-bottom: 20px !important;
            line-height: 32px !important;
        }

        p {
            font-size: 14px !important;
        }
    }
}
