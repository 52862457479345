//Primary Colors
:root {
    --primary-1: #00578a;
    --primary-2: #053c74;
    --primary-3: #164460;
    --primary-4: #1772bf;
    --primary-5: #70aae6;
    --primary-6: #f7fbff;
    --primary-7: #c3e9ff;
    --primary-8: #f0f7fc;
    --primary-9: #1b73a6;
    --primary-10: #47a7f5;
    --primary-11: #4b85b0;
    --primary-12: #7accd8;
    --primary-13: #F8FCFF;

    //Neutrals
    --neutral-1: #454545;
    --neutral-2: #707070;
    --neutral-3: #ffffff;
    --neutral-4: #fafafa;
    --neutral-5: #787878;
    --neutral-6: #e9e9e9;
    --neutral-7: #e0dede;
    --neutral-8: #f0f2f2;
    --neutral-9: #dce0e0;
    --neutral-10: #f9f9f9;
    --neutral-11: #b3b3b3;
    --neutral-12: #d1d5da;
    --neutral-13: #302F4BD9;
    --neutral-14: #000000;
    --neutral-15: #C7C7C7;
    --neutral-16: #E6F5FB;
    --neutral-17: #0A0C1F;


    //Acents
    --accent-1: #2d8735;
    --accent-2: #61b861;
    --accent-3: #005907;
    --accent-4: #bfe3bf;
    --accent-5: #EEF5EE;

    --alert-1: #dd3333;
    --alert-2: #fddfdf;
    --alert-3: #b51b1b;
    --alert-4: #d50000;

    --warning-1: #f3ba4d;
    --warning-2: #fda50f;
    --warning-3: #fff3a5;
    --warning-4: #FFFDF2;
    --purple: #7445b2;

    --custom-shadow: 0 2px 20px 0 rgba(122, 77, 77, 0.05);
    --box-shadow-1: 1px 2px 6px rgb(0 0 0 / 11%);
    --box-shadow-2: 1px 2px 6px rgba(0, 0, 0, 0.11);
    --card-shadow: 0 2px 8px rgba(0, 0, 0, 0.11);
    --overlay-color: rgba(45, 135, 53, 0.5);

    --background-color-1: rgba(255, 255, 255, 0.96);

    --modal-overlay-color: rgba(0, 0, 0, 0.32);

    --linear-gradient: linear-gradient(#bcdaff, #ffffff);

    --black-linea-gradient: linear-gradient(180deg,
            rgba(88, 88, 88, 0) 65.27%,
            rgba(40, 40, 40, 0.994792) 100%,
            #181818 100%);
    --mat-label-color: rgba(0, 0, 0, 0.54);

    --emoji-size: 156px;

    --pending-status-color: #f3ba4d;
    --main-container-background: #f8fafc;
}