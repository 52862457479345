.margin-left-20 {
    margin-left: 20px;
}


.tour-round-button {
    @extend .round-button;
    @include flex(row, center, center);
    height: 36px !important;
    background-color: transparent !important;
    border: 1px solid var(--primary-1) !important;
    color: var(--primary-1) !important;
    border-radius: 50% !important;
    padding: 0 !important;

    &:hover {
        border-color: var(--primary-3) !important;

        span {
            color: var(--primary-3) !important;
        }
    }
}

.tour-square-button {
    @extend .base-button;
    background-color: var(--primary-1) !important;
    background-position: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    color: var(--neutral-3) !important;
    font-weight: 200 !important;
}

.shepherd-cancel-icon {
    font-weight: 400 !important;
    color: var(--neutral-1) !important;
}

.shepherd-header {
    background: var(--neutral-6) !important;
}

.shepherd-text,
.shepherd-footer {
    background: var(--neutral-4) !important;
}

.shepherd-text {
    padding: 16px !important;
}