::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0.06);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}