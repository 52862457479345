footer {
    padding: 12px 0px;
    text-align: center;

    .row {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 60px;
    }

    .container {
        max-width: 100%;
        align-items: center;
    }

    span.copyright {
        font-size: 90%;
        line-height: 40px;
        text-transform: none;
        font-family: 'Roboto', sans-serif;
    }

    ul.quicklinks {
        font-size: 90%;
        line-height: 40px;
        margin-bottom: 0;
        text-transform: none;
        font-family: 'Roboto', sans-serif;
    }
}

ul.social-buttons {
    margin-bottom: 0;

    li a {
        font-size: 8px;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        color: var(--neutral-3);
        border-radius: 100%;
        outline: none;
        background-color: var(--neutral-2);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--primary-1);
        }
    }
}

@media (min-width: 520px) {
    #footer {
        .row {
            justify-content: space-between !important;
        }
    }
}
