.team-member {
    margin-bottom: 50px;
    text-align: center;

    img {
        @include size(160px, 160px);
        border: 7px solid var(--neutral-3);
        filter: grayscale(100%);

        &:hover {
            filter: none;
        }
    }

    h4 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 0;
        text-transform: none;
    }

    p {
        margin-top: 0;
    }
}
