@import '../../../../../../libs/generics//src/lib/styles/mixins.scss';

#subscriptions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        margin-bottom: 80px;
    }

    .subscriptions-container {
        width: 100%;
        padding: 10px;
        @include flex(row, center, center);
        flex-wrap: wrap;

        app-card {
            @include size(380px, 480px);
            margin: 12px;

            .furban-card {
                height: 100% !important;
                padding: 28px 28px;
                color: var(--neutral-1);

                &.hoverable:hover {
                    transform: translate(0, 1px);
                }
            }

            &:hover {
                transform: scale(1.05);
                transform-origin: 50% 0%;
                transform: translate3d(0, 0, 0);

                .secondary-button {
                    color: var(--neutral-3);
                    background-color: var(--primary-1);
                    box-shadow: var(--card-shadow);
                }
            }

            a:hover {
                color: var(--neutral-3);
            }

            .subscription-name {
                font-size: 32px;
                font-weight: 300;
            }

            h1 {
                font-size: 24px;
                font-weight: 550;
                margin-top: 8px;
                margin-bottom: 16px;
            }

            h5 {
                font-size: 16px;
                font-weight: 500;
                color: var(--neutral-2);
            }
        }

        .furban-header,
        .furban-content,
        .furban-footer {
            padding: 0;
        }

        .furban-footer {
            position: static;
            overflow: visible;
        }

        ul li {
            margin-bottom: 12px;
            list-style: none;
            font-weight: 500;
        }

        li::before {
            content: '';
            width: 10px;
            height: 10px;
            margin-right: 12px;
            border-radius: 50%;
            position: sticky;
            display: inline-block;
            background-color: var(--neutral-2);
        }
    }
}

@media (max-width: 1200px) {
    .subscriptions-container {
        app-card {
            margin-left: 40px;
            margin-bottom: 40px;
        }
    }
}