@import './furban-info/subscriptions';
@import './furban-info/nav';
@import './furban-info/timeline';
@import './furban-info/masthead';
@import './furban-info/partners';
@import './furban-info/roadmap';
@import './furban-info/team';
@import './furban-info/contact';
@import './furban-info/footer';
@import './furban-info/projects-around';

furban-info {
    .btn-primary {
        background-color: var(--accent-1);
        border-color: var(--accent-1);
        color: var(--neutral-3);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--accent-1) !important;
            border-color: var(--accent-1) !important;
            color: var(--neutral-3);
        }

        &:active,
        &:focus {
            -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
            box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
        }
    }

    .main-container {
        overflow-y: scroll;
    }

    .bg-light {
        background-color: var(--neutral-4) !important;
    }

    .flex-center {
        justify-content: center;
    }

    p {
        line-height: 1.75;
    }

    a {
        color: var(--primary-1);

        &:hover {
            color: var(--primary-1);
            text-decoration: none;
        }
    }

    .center-container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
    }

    section {
        padding: 100px 0;

        h2.section-heading {
            font-size: 52px;
            font-weight: 300;
            margin-top: 0;
            margin-bottom: 16px;
            color: var(--neutral-1);
        }

        h3.section-subheading {
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 100px;
            text-transform: none;
            font-family: 'Roboto', sans-serif;
        }
    }

    @media (min-width: 768px) {
        section {
            padding: 100px 0;
        }
    }

    .btn {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
    }

    .btn-xl {
        font-size: 18px;
        padding: 20px 40px;
    }

    img {
        &::-moz-selection,
        &::selection,
        &::-moz-selection {
            background: transparent;
        }
    }
}
