@import '../../../../../libs/generics/src/lib/styles/mixins-no-select.scss';
@import 'project-header.scss';

.flex-column-center {
    @include flex(column, center, center);
}

.flex-column-start {
    @include flex(column, center, flex-start);
}

.flex-column-end {
    @include flex(column, center, flex-end);
}

.flex-row-center {
    @include flex(row, center, center);
}

.flex-row-spaced {
    @include flex(row, space-between, center);
}

.flex-column-spaced {
    @include flex(column, space-between, center);
}

.flex-row-end {
    @include flex(row, flex-end, center);
}

.flex-row-start {
    @include flex(row, flex-start, center);
}

.flex-row-start-start {
    @include flex(row, flex-start, flex-start);
}

.background-center {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.wrap {
    flex-wrap: wrap;
}

.max-content {
    width: max-content;
}

.flex-default {
    @include flex();
}

.no-overflow {
    overflow: hidden;
}

.page-title {
    font-weight: 200;
    color: var(--neutral-1);
    font-size: 24px;
    margin-bottom: 0;
}

.spaced-container {
    @include flex($justify: space-between, $align: center);
    padding: 30px 34px;
}

.buttons-container {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    button {
        margin-top: 20px;
    }

    &.end {
        justify-content: flex-end;
    }
}

.important-space-between {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.buttons-right-container {
    @include flex($justify: flex-end);
    margin-top: 20px;

    button:not(:last-of-type) {
        margin-right: 16px;
    }
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.hide {
    display: none !important;
}

.placeholder-text {
    width: 50%;
    color: var(--neutral-1);
    font-size: 18px;
    padding: 20px 12px;
    text-align: center;
}

.no-select {
    @include no-select;
}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.grab {
    cursor: grab !important;
}

@media (max-width: 800px) {
    .placeholder-text {
        width: 80%;
    }
}

.blur {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}

.inline-flex {
    display: inline-flex;
    width: 100%;
}

.rotate {
    transition: transform ease-in 0.3s;
    transform: rotate(180deg);
}

.circle {
    @include size(20px, 20px);
    border-radius: 50%;
    margin-left: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.32);

    &.active {
        background-color: var(--accent-2);
    }

    &.inactive {
        background-color: var(--alert-1);
    }

    &.ended {
        background-color: var(--warning-1);
    }
}

.overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.close-button {
    background-color: transparent;
    color: var(--neutral-4);
    padding-bottom: 8px;

    span {
        font-size: 16px;
        cursor: pointer;
    }
}

.reported-icon {
    color: var(--alert-1) !important;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.word-break-normal {
    word-break: normal;
}

.container-btn-back {
    @include flex(row, flex-start, center);

    .mat-icon {
        @include flex(row, center, center);
        @include size(20px, 20px);

        cursor: pointer;
        margin-right: 16px;
        font-size: 20px;
    }
}

@media (max-width: 1400px) {
    .stretch-content {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 300px;
    }
}

@media (max-width: 800px) {
    .max-content {
        display: none;
    }
}

@media (max-width: 700px) {
    .filter-panel {
        li {
            font-size: 14px;
            margin-left: 12px;
        }
    }
}

.error {
    color: var(--alert-1);
}