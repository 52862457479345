#contact {
    background-color: var(--neutral-1);
    background: linear-gradient(rgba(48, 48, 48, 0.4), rgba(48, 48, 48, 0.8)),
        url('../../images/homepage/background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    a {
        font-weight: 300;
        font-size: 24px;
    }

    .btn {
        color: var(--neutral-3);
        background-color: var(--accent-2);
        border: none;
        box-shadow: var(--card-shadow);
        padding: 12px 24px;

        &:hover {
            background-color: var(--neutral-3) !important;
            color: var(--accent-2);
        }
    }
}
