$paddings: 0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 64;
$widths: 10, 15, 20, 32, 30, 40, 42, 50, 60, 70, 80, 90, 95;

/*
  For example if in HTML you have the class padding-8-16 the code below will generate
  a CSS class that will have the property -> padding: 8px 16px;
*/
@each $i in $paddings {
    @each $j in $paddings {
        .padding-#{$i}-#{$j} {
            padding: #{$i}px #{$j}px;
        }
    }
}

/*
  For example if in HTML you have the class padding-8 the code below will generate
  a CSS class that will have the property -> padding: 8px ;
*/
@each $i in $paddings {
    .padding-#{$i} {
        padding: #{$i}px;
    }
}

@each $i in $widths {
    .custom-width-#{$i} {
        width: round(percentage(calc($i/100))) !important;
    }
}

@each $i in $widths {
    .elipsis-on-exceeding-width-#{$i} {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: round(percentage(calc($i/100)));
    }
}
