@import '@angular/material/theming';
//@import '../../node_modules/@angular/material/theming';
@include mat-core();
$md-pallete-primary: (
    50: #e0ebf1,
    100: #b3cddc,
    200: #80abc5,
    300: #4d89ad,
    400: #26709c,
    500: #00578a,
    600: #004f82,
    700: #004677,
    800: #003c6d,
    900: #002c5a,
    A100: #8bb9ff,
    A200: #589aff,
    A400: #257bff,
    A700: #0c6bff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);
$md-pallete-secondary: (
    50: #e3e9ec,
    100: #b9c7cf,
    200: #8ba2b0,
    300: #5c7c90,
    400: #396078,
    500: #164460,
    600: #133e58,
    700: #10354e,
    800: #0c2d44,
    900: #061f33,
    A100: #6cb3ff,
    A200: #3999ff,
    A400: #067eff,
    A700: #0072ec,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$md-pallete-warn: (
    50: #fce0e9,
    100: #f7b3c7,
    200: #f180a2,
    300: #eb4d7d,
    400: #e72661,
    500: #e30045,
    600: #e0003e,
    700: #dc0036,
    800: #d8002e,
    900: #d0001f,
    A100: #fff8f9,
    A200: #ffc5ca,
    A400: #ff929c,
    A700: #ff7985,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$furban-app-primary: mat-palette($md-pallete-primary, 500);
$furban-app-accent: mat-palette($md-pallete-secondary, 500);
$furban-app-warn: mat-palette($mat-red);

$furban-app-theme: mat-light-theme(
    $furban-app-primary,
    $furban-app-accent,
    $furban-app-warn
);

@include angular-material-theme($furban-app-theme);

$custom-typography: mat-typography-config(
    $font-family: 'Roboto',
);
@include mat-core($custom-typography);

.mat-dialog-actions {
    margin: 0px !important;
    padding: 0px !important;
    min-height: unset !important;
    display: flex;
    justify-content: flex-end;
}

.mat-dialog-title {
    display: flex !important;
    justify-content: space-between;
}
