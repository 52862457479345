@mixin flex($alignment: false, $justify: false, $align: false) {
    display: flex;

    @if $alignment {
        flex-direction: $alignment;
    }

    @if $justify {
        justify-content: $justify;
    }

    @if $align {
        align-items: $align;
    }
}

@mixin pos($pos: true, $t: false, $r: false, $b: false, $l: false, $z: false) {
    @if $pos {
        position: $pos;
    }

    @if $t {
        top: $t;
    }

    @if $r {
        right: $r;
    }

    @if $b {
        bottom: $b;
    }

    @if $l {
        left: $l;
    }

    @if $z {
        z-index: $z;
    }
}

@mixin size($w: false, $h: false) {
    @if $w {
        width: $w;
    }

    @if $h {
        height: $h;
    }
}

@mixin font($size: false, $colour: false,  $fontWeight: false, $lh: false) {
    @if $size {
        font-size: $size;
    }

    @if $colour {
        color: $colour;
    }

    @if $lh {
        line-height: $lh;
    }

    @if $fontWeight {
        font-weight: $fontWeight;
    }
}