@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8OUuhs.ttf)
        format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFVZ0e.ttf)
        format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOUuhs.ttf)
        format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOUuhs.ttf)
        format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN8rsOUuhs.ttf)
        format('truetype');
}

/*Custom Furban style*/
.ol-popup {
    width: 300px !important;
    height: 256px !important;
    overflow: visible !important;
    padding: 0 !important;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.11);
    border-radius: 4px;
}

#marker-close {
    position: absolute;
    width: 26px;
    height: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--neutral-3);
    border-radius: 50%;
    top: 8px !important;
    right: 8px !important;
}
/*end of Furban's style*/

body,
html {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Arial;
}
body.wait *,
body.wait {
    cursor: progress !important;
}
.ol-zoom {
    left: 10px !important;
    top: 10px !important;
    background: none !important;
}
.ol-zoom .ol-zoom-in,
.ol-zoom .ol-zoom-out {
    width: 26px !important;
    height: 26px !important;
    color: #b8bfd8 !important;
    border: 1px solid #e3e9f7 !important;
    background-color: white !important;
    font-family: Arial !important;
    font-size: 26px !important;
    line-height: 26px !important;
    border-radius: 0 !important;
    cursor: pointer !important;
}
.ol-zoom .ol-zoom-in:hover,
.ol-zoom .ol-zoom-out:hover {
    background-color: white !important;
}
.ol-zoom .ol-zoom-in {
    margin-bottom: -2px !important;
}
.ol-scale-line {
    background-color: white !important;
    position: static !important;
}
.ol-scale-line-inner {
    border: 1px solid #3a4870 !important;
    border-top: none !important;
    color: #3a4870 !important;
    font-size: 13px;
}
.ol-rotate {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 10px !important;
    top: 65px !important;
    background: none !important;
}
.ol-control button:focus,
.ol-control button:hover {
    text-decoration: none;
    background-color: white !important;
}
.ol-control button {
    background-color: white !important;
    border: 1px solid #e3e9f7 !important;
    border-radius: 0 !important;
    width: 26px !important;
    height: 26px !important;
}
.ol-compass {
    color: #3a4870;
    font-weight: bolder;
}
.ol-attribution.ol-uncollapsible ul {
    color: #3a4870;
}
/*.ol-compass:before {
    content: "\2191";
}
.ol-compass:after {
    content: "\2191";
}*/
#geocompStatusTable {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 26px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
    font-size: 13px;
    display: none;
    border-top: 1px solid #e3e9f7;
}
#geocompStatusInfo {
    text-align: left;
    font-size: 13px;
    color: #3a4870;
    margin-left: 5px;
}
#geocompStatusMouse,
#geocompStatusVersion,
#geocompStatusScaleNumber {
    color: #3a4870;
}
#geocompStatusNavigateGeoLocation {
    width: 18px;
    height: 18px;
    display: block;
    cursor: pointer;
}
#geocompStatusNavigateGeoLocation svg path {
    fill: #3a4870;
}
#geocompStatusTrackGeoLocation {
    width: 24px;
    height: 24px;
    display: block;
    cursor: pointer;
}
#geocompStatusTrackGeoLocation svg path {
    fill: #3a4870;
}
#geocompStatusTrackGeoLocation.active svg path {
    fill: #19a6fe;
}
#geocompStatusTable .geoLocationButtons {
    width: 20px;
}
#geocompStatusTable .scaleLine {
    width: 10px;
}
#geocompStatusTable .scaleNumber {
    width: 125px;
}
#geocompStatusTable .mouseCoordinates {
    width: 145px;
}
#geocompStatusTable .version {
    width: 50px;
}
.ol-popup {
    position: absolute;
    background-color: white;
    padding: 15px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    border: 1px solid #e3e9f7;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
    z-index: 1000;
}
.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
.ol-popup:before {
    border-top-color: #e3e9f7;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
#markerinfo {
    height: 100px;
}

#markerinfo-content {
    height: calc(100% - 10px);
    font-size: 14px;
    color: #3a4870;
    padding: 4px;
    border: 1px solid #e3e9f7;
}
#markerinfo-navbar {
    position: absolute;
    width: 28px;
    top: 25px;
    bottom: 15px;
    right: 3px;
    overflow: hidden;
}
#cluster-prev,
#cluster-next {
    position: absolute;
    border: 1px solid #e3e9f7;
    width: 20px;
    height: 20px;
    right: 0;
    cursor: pointer;
}
.cluster-nav-disabled {
    background-color: #cfdce6;
}
#cluster-next {
    bottom: 0;
}
#marker-close {
    position: absolute;
    width: 26px;
    height: 26px;
    cursor: pointer;
    top: 0;
    right: 0;
}
#cluster-prev svg path,
#cluster-next svg path,
#marker-close svg path {
    fill: #b8bfd8;
}
#mapLayerPanel {
    font-family: Open Sans;
    display: none;
    position: absolute;
    background-color: white;
    color: #3a4870;
    width: 0;
    right: 10px;
    top: 10px;
    z-index: 999;
    font-size: 16px !important;
    font-weight: 400;
    bottom: 50px;
    border-left: 1px solid #e3e9f7;
    border-right: 1px solid #e3e9f7;
    border-bottom: 1px solid #e3e9f7;
}
.layerPanelShowButton {
    width: 38px;
    height: 38px;
    border: 1px solid #e3e9f7;
    background-color: white;
    right: 10px;
    top: 10px;
    position: absolute;
    z-index: 999;
    display: none;
    cursor: pointer;
}
.layerPanelShowButton svg {
    position: absolute;
    left: 2px;
    top: 2px;
}
.layerPanelShowButton svg path {
    fill: #b8bfd8;
}
.layerPanelHideButton {
    position: absolute;
    top: 9px;
    width: 32px;
    height: 32px;
    display: none;
    cursor: pointer;
}
.layerPanelHideButton svg path {
    fill: #b8bfd8;
}
#mapLayerPanel .legendImage {
    margin-top: 4px;
}
#layerPanelHeader {
    border-top: 1px solid #e3e9f7;
    border-bottom: 1px solid #e3e9f7;
    height: 48px;
    line-height: 48px;
    background: #fcfcfe;
}
#layerPanelTitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    height: 38px;
    line-height: 38px;
    margin-left: 53px;
}
#layerPanelContent {
    margin: 5px 0 0 16px;
    position: absolute;
    top: 50px;
    bottom: 4px;
    width: 284px;
    overflow: auto;
}
.layerPanelToggle {
    display: inline-block;
    cursor: pointer;
    height: 15px;
    width: 15px;
}
.layerPanelToggle svg {
    height: 16px;
    width: 16px;
    margin-left: 2px;
}
.layerPanelToggle svg path {
    fill: #b8bfd8;
}
#layerPanelContent .layerPanelGroup {
    margin-top: 14px;
}
#layerPanelContent .header {
    height: 22px;
}
#layerPanelContent .header .groupTitle,
#layerPanelContent .header .layerTitle {
    display: inline-block;
    color: #3a4870;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    position: absolute;
    left: 45px;
}
#layerPanelContent .groupLayers .header .layerTitle {
    left: 64px;
}
#layerPanelContent .layerPanelGroup .groupLayers {
    margin-bottom: 38px;
}
#layerPanelContent .layer {
    margin-top: 14px;
}
#layerPanelContent .layerPanelGroup .groupLayers .layer {
    margin-left: 22px;
    margin-top: 14px;
}
#layerPanelContent .layerContent {
    margin-left: 22px;
    margin-top: 4px;
}
.invisible {
    display: none;
}
#layerPanelContent .togglePlaceHolder {
    display: inline-block;
    width: 15px;
    height: 2px;
    background-color: white;
}
.opacityControl {
    display: inline-block;
    width: 112px;
    height: 32px;
    border: 1px solid #e3e9f7;
    border-radius: 8px;
}
.opacityButton {
    display: inline-block;
    width: 32px;
    height: 32px;
    cursor: pointer;
    text-align: center;
    line-height: 36px;
    background: #f1f7fc;
}
.opacityButton svg {
    width: 16px;
    height: 16px;
}
.layerOpacityButtonUp {
    border-radius: 8px 0 0 8px;
}
.layerOpacityButtonDown {
    border-radius: 0 8px 8px 0;
}
.opacityButton svg path {
    fill: #b8bfd8;
}
.opacityValue {
    display: inline-block;
    width: 46px;
    height: 32px;
    border-left: 1px solid #e3e9f7;
    border-right: 1px solid #e3e9f7;
    text-align: center;
    vertical-align: top;
    line-height: 32px;
}
#mapLayerPanel input[type='checkbox'] {
    visibility: hidden;
}
#mapLayerPanel .checkbox-custom {
    display: inline-block;
    position: absolute;
    margin-left: 5px;
    margin-right: 4px;
}
#mapLayerPanel .checkbox-custom label {
    cursor: pointer;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
    background: white;
    border: 1px solid #e3e9f7;
    border-radius: 4px;
}
#mapLayerPanel .checkbox-custom label:after {
    opacity: 0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 2px;
    left: 2px;
    border: 1px solid #b8bfd8;
    border-top: none;
    border-right: none;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#mapLayerPanel .checkbox-custom input[type='checkbox']:checked + label:after {
    opacity: 1;
}
#mapLayerPanel .checkbox-custom input[type='checkbox']:disabled + label {
    background-color: #e2ecf3 !important;
}
#mapLayerPanel .checkbox-custom input[type='checkbox']:checked + label {
    background-color: #b8bfd8;
}
#mapLayerPanel
    .checkbox-custom
    input[type='checkbox']:disabled:checked
    + label:after {
    opacity: 1;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 2px;
    left: 2px;
    border: 1px solid #9dabba;
    border-top: none;
    border-right: none;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#mapLayerPanel .checkbox-custom input[type='checkbox']:checked + label:after {
    border-left: 1px solid white;
    border-bottom: 1px solid white;
}
/*@media only screen and (min-resolution : 1dppx), (-webkit-min-device-pixel-ratio: 1 ) {
    #layerPanelTitle {
        background-color: yellow;
    }
}*/
@media only screen and (min-resolution: 2dppx),
    (-webkit-min-device-pixel-ratio: 2),
    (max-device-width: 800px) and (max-device-height: 1280px) and (min-resolution: 1dppx),
    (max-device-width: 800px) and (max-device-height: 1280px) and (-webkit-min-device-pixel-ratio: 1),
    (max-device-width: 1280px) and (max-device-height: 800px) and (min-resolution: 1dppx),
    (max-device-width: 1280px) and (max-device-height: 800px) and (-webkit-min-device-pixel-ratio: 1) {
    #geocompStatusMouse {
        display: none;
    }
}
/*@media only screen and (min-resolution : 3dppx), (-webkit-min-device-pixel-ratio: 3 ) {
        background-color: green;
    }
}*/
/*@media only screen and (min-resolution : 4dppx), (-webkit-min-device-pixel-ratio: 4 ) {
    #layerPanelTitle {
        background-color: blue;
    }
}*/
