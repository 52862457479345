/* You can add global styles to this file, and also import other style files */
@import '@ctrl/ngx-emoji-mart/picker';
//@import '../node_modules/@ctrl/ngx-emoji-mart/picker';
@import 'themes/theme-furban';
@import 'assets/styles/map-popup';
@import 'assets/styles/spacings';
@import 'assets/styles/layout';
@import 'assets/styles/tour';
@import '../../../libs/generics/src/lib/styles/pallete.scss';
@import '../../../libs/generics/src/lib/styles/fonts.scss';
@import '../../../libs/generics/src/lib/styles/buttons.scss';
@import '../../../libs/generics/src/lib/styles/scroll.scss';


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-family: Roboto, sans-serif;
}

table {
    border-collapse: separate;
}

button:focus {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0;
    user-select: none;
}

.mat-checkbox-inner-container {
    height: 26px !important;
    width: 26px !important;
    margin-right: 8px !important;
}

.mat-checkbox-checked .mat-checkbox-background {
    background-color: var(--primary-1) !important;
}

.mat-calendar-body-cell-content {
    color: var(--mat-label-color);
}

.mat-calendar-body-selected {
    background-color: var(--primary-1);
    color: var(--neutral-3);
}

.mat-checkbox-label {
    text-decoration: underline !important;
    line-height: 26px !important;
}

button {
    /* padding: 5px 25px 5px 25px!important; */
    /* font-size: 22px!important;  */
    font-weight: bold !important;
}

body {
    overflow: hidden;
}

html,
body {
    height: 100%;
    width: 100%;
}

.mat-snack-bar-container {
    padding: 0 !important;
    max-width: unset !important;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 12px !important;
    margin-top: 12px !important;
    border-radius: 4px !important;
}

.mat-table {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
}

.mat-dialog-title h2 {
    font-size: 24px;
    margin-bottom: 0;
}

.mat-menu-item {
    font-weight: 400 !important;
    outline: none !important;
}

.mat-elevation-z2 {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05) !important;
}

.selectBox {
    border: 1px solid #55aaff;
    background-color: rgba(50, 82, 119, 0.3);
    position: fixed;
}

.texture-select-dropdown {
    margin-top: 60px !important;
    max-width: 160px !important;
    display: flex;
    flex-direction: column;
    padding: 8px;

    .mat-option {
        padding: 4px;
    }

    .mat-option-text {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
