header.masthead {
    text-align: center;
    color: var(--neutral-3);
    background: linear-gradient(0deg, rgb(48, 48, 48), rgba(48, 48, 48, 0.2)),
        url('../../images/homepage/background-login.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .intro-text {
        .intro-lead-in {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 28px;
            font-family: 'Roboto', sans-serif;
        }

        .intro-heading {
            font-size: 1.5em;
            font-weight: 700;
            line-height: 50px;
            margin-bottom: 28px;
            font-family: 'Roboto', sans-serif;
        }

        .arrow {
            transition: transform 1s;

            &:hover {
                transform: scale(1.5);
            }
        }
    }
}

@media (min-width: 768px) {
    header.masthead .intro-text {
        .intro-lead-in {
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 32px;
            font-family: 'Roboto', sans-serif;
        }

        .intro-heading {
            font-size: 56px;
            font-weight: 700;
            line-height: 75px;
            margin-bottom: 50px;
            font-family: 'Roboto', sans-serif;
        }

        .arrow {
            transition: transform 1s;

            &:hover {
                transform: scale(1.5);
            }
        }
    }
}

.list-inline-item,
.nav-item {
    a {
        cursor: pointer;
    }
}
