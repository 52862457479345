@import '../../../../../libs/generics/src/lib/styles/mixins-common.scss';

.project-header-common {
    @include pos(absolute, $z: 999);
    width: calc(100% - 78px);
    background-color: var(--neutral-3);
    box-shadow: var(--box-shadow-1);
}

.header-space-between {
    @include flex($justify: space-between);
    @extend .project-header-common;
    margin-right: 12px;
}

.header-center {
    @include flex($justify: center);
    @extend .project-header-common;
}