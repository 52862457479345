#partners {
    .partner-picture {
        width: 146px;
        height: 146px;
        border-radius: 50%;
        box-shadow: var(--card-shadow);
    }

    // img {
    //   box-shadow: var(--card-shadow);
    // }

    .service-heading {
        font-size: 24px;
        font-weight: 500;
        margin: 15px 15px;
        text-transform: none;
    }
}
