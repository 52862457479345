#roadMap {
    .container {
        max-width: 100%;
    }

    model-viewer {
        height: 600px;
        width: auto;
        margin: 0 auto;
    }
}
