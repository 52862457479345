@import '../../../../../libs/generics/src/lib/styles/mixins-common.scss';
@import '../../../../../libs/generics/src/lib/styles/mixins-no-select.scss';
@import '../../../../../libs/generics/src/lib/styles/mixins-grid-default.scss';


$breaking-points: (
    mobile: 480px,
    tablet: 1024px,
    desktop: 1440px,
);

@mixin query($screen) {

    @each $key,
    $value in $breaking-points {
        @if ($screen ==$key) {
            @media (max-width: $value) {
                @content;
            }
        }
    }
}

@mixin set-center-background-image($image, $color1: false, $color2: false) {
    @if $color1 and $color2 {
        background: linear-gradient($color1, $color2),
            url($image) no-repeat center;
        background-size: cover;
    }

    @else if $color1 {
        background: linear-gradient($color1, $color1),
            url($image) no-repeat center;
        background-size: cover;
    }

    @else {
        background: url($image) no-repeat center;
        background-size: cover;
    }
}

@mixin font($size: false, $colour: false, $lh: false) {
    @if $size {
        font-size: $size;
    }

    @if $colour {
        color: $colour;
    }

    @if $lh {
        line-height: $lh;
    }
    
}

@mixin constrain-width($width) {
    width: $width;
    max-width: $width;
    min-width: $width;
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}

@mixin elipsis-on-line($line, $height) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    white-space: initial;
    height: $height;
}

@mixin hide {
    display: none;
}

@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    border-radius: 50%;

    >* {
        display: block;
        @include pos(absolute, $t: 50%, $l: 50%);

        margin: - calc($item-size / 2);
        width: $item-size;
        height: $item-size;

        $angle: calc(360 / $item-count);
        $rot: 0;

        @for $i from 1 through $item-count {
            &:nth-of-type(#{$i}) {
                transform: rotate(calc($rot * -1deg)) translate(calc($circle-size / 2)) rotate(calc($rot * 1deg));
            }

            $rot: $rot + $angle;
        }

        &:last-child {
            display: unset;
            @include pos(unset, $t: unset, $l: unset);
            margin: unset;
            transform: none;
        }
    }
}