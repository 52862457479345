@import 'mixins.scss';

.base-button {
  @include no-select;
  display: -webkit-box;
  @include flex(row, center, center);
  border-radius: 4px;
  height: 36px;
  min-height: 36px;
  padding: 0 16px;
  outline: none !important;
  border: none;
  cursor: pointer;

  h6 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 200;
    font-family: 'Roboto', sans-serif;
  }

  &.disabled {
    pointer-events: none;
    background-color: var(--neutral-6);
    border-color: var(--neutral-6);

    h6 {
      color: var(--neutral-2);
    }
  }
}

.primary-button {
  @extend .base-button;
  background-color: var(--primary-1);
  background-position: center;
  transition: background 0.8s;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);

  &:hover {
    background: var(--primary-3) radial-gradient(circle, transparent 1%, var(--primary-1) 1%) center/15000%;
  }

  &:active {
    background-color: var(--primary-9);
    background-size: 100%;
    transition: background 0s;
  }

  &.small {
    height: 32px;

    h6 {
      font-size: 14px;
    }
  }

  &.space-left {
    margin-left: 20px;
  }

  &.green {
    background-color: var(--accent-2);

    &:hover {
      background-color: var(--accent-1);
    }
  }

  h6 {
    color: var(--neutral-3);
  }

  svg {
    color: var(--neutral-3);
  }
}

.secondary-button {
  @extend .base-button;
  background-color: transparent;
  border: 1px solid var(--primary-1);
  color: var(--primary-1);

  &:hover:not(.white) {
    border-color: var(--primary-3);
    color: var(--primary-3);
  }

  &.white {
    border-color: var(--neutral-3);
    color: var(--neutral-3);
  }

  &.square {
    width: 40px;
    padding: 0;
  }

  &.round {
    width: 36px;
    @include flex(row, center, center);
    border-radius: 50%;
    padding: 0;
  }

  h6 {
    font-weight: 400;
  }
}

.tertiary-button {
  @extend .secondary-button;
  border: none;
}

.red-button {
  @extend .base-button;
  background-color: transparent;
  border: 1px solid var(--alert-1);
  color: var(--alert-1);

  &:hover {
    border-color: var(--alert-3);
    color: var(--alert-3);
  }

  h6 {
    font-weight: 400;
  }
}

.tooling-button {
  @extend .base-button;
  @include flex(row, center, center);
  width: 36px;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
  color: var(--primary-1);

  @media (hover: hover) {
    &:hover {
      span {
        color: var(--accent-2);
      }
    }

    .mat-icon {
      &:hover {
        color: var(--accent-2);
      }
    }
  }

  &.active {
    span {
      color: var(--accent-2);
    }
  }

  .mat-icon {
    &.active {
      color: var(--accent-2);
    }
  }
}

.round-button {
  @extend .base-button;
  @include flex(row, center, center);
  width: 36px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  background-color: var(--neutral-3);

  @media (hover: hover) {
    &:hover {
      span {
        color: var(--accent-1);
      }

      &.colored {
        span {
          color: var(--neutral-3);
        }
      }
    }
  }

  &.active {
    span {
      color: var(--accent-1);
    }
  }

  &.inactive {
    pointer-events: none;

    span {
      color: var(--neutral-6);
    }
  }

  span {
    color: var(--primary-2);
  }

  &.colored {
    @include size(40px, 40px);
    min-width: 40px;
    max-width: 40px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    background-color: var(--accent-1);

    span {
      font-size: 20px;
      color: var(--neutral-3);
    }
  }
}

.floating-buttons {
  @include flex(row, $align: center);
  position: absolute;
  top: 12px;
  z-index: 999;
  background-color: var(--neutral-3);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 8px 12px;

  &.left {
    left: 12px;
  }

  &.right {
    right: 12px;
  }

  &.bottom {
    bottom: 40px;
    top: unset;
  }

  button:not(:last-of-type) {
    margin-right: 16px;
  }
}

.switch-button-custom {
  @include flex(row, center, center);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--neutral-3);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 99;

  &.full-screen {
    @include pos(absolute, $b: 20px, $r: 10px);
  }

  &.safety {
    @include pos(absolute, $b: 20px, $l: 20px, $z: auto);
  }

  &.enabled {
    background-color: var(--accent-2) !important;

    .material-icons {
      color: var(--neutral-3) !important;
    }
  }

  svg {
    width: 40px;
    height: 40px;
    fill: var(--accent-1);
  }

  .material-icons {
    font-size: 30px;
    color: var(--primary-1);
  }
}

.ghost-button {
  @include flex(row, center, center);
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.green-icon {
  color: var(--accent-1);
  margin-right: 12px;
}

.green {
  color: var(--accent-1);
}

.red {
  color: var(--alert-3);
}

.card-action-button {
  @extend .ghost-button;

  span {
    color: var(--neutral-3);
  }

  &:hover {
    span {
      color: var(--accent-2);
    }
  }
}

.home {
  cursor: pointer;
  font-size: 28px;
  color: var(--accent-2);
  margin-right: 12px;
}

.remove {
  color: var(--alert-1) !important;
  cursor: pointer !important;
}

.disabled {
  h6 {
    color: var(--neutral-2) !important;
  }

  span {
    color: var(--neutral-2) i !important;
  }
}

.control-btn {
  @extend .round-button;
  border-radius: 4px;

  &.active {
    background-color: var(--primary-2);

    span {
      color: var(--neutral-3);
    }
  }
}