#mainNav {
    z-index: 9999;
    background-color: var(--neutral-3);
    box-shadow: var(--card-shadow);

    img {
        cursor: pointer;
        width: 120px;
    }

    ul.navbar-nav {
        align-items: center;
    }

    .navbar-toggler {
        font-size: 12px;
        padding: 12px 0;
        color: var(--primary-1);
        border: 0;
        background-color: var(--neutral-3);
        font-family: 'Roboto', sans-serif;
    }

    .navbar-nav .nav-item .nav-link {
        font-size: 90%;
        font-weight: 400;
        padding: 0.75em 0;
        letter-spacing: 1px;
        color: var(--neutral-1);
        font-family: 'Roboto', sans-serif;

        &.active,
        &:hover {
            color: var(--primary-1);
        }
    }

    .secondary-button {
        border-color: var(--primary-1);
        color: var(--primary-1);
        border-width: 1px;
        font-weight: 450;
        margin-bottom: 16px !important;
        margin-top: 16px !important;

        &:hover {
            border-color: var(--primary-1) !important;
            color: var(--primary-1) !important;
        }
    }

    .primary-button {
        background-color: var(--primary-1);
        color: var(--neutral-3);
        transition: none;
        font-weight: 450;

        &:hover {
            background-color: var(--primary-1);
            color: var(--neutral-3) !important;
        }
    }
}

@media (min-width: 1200px) {
    #mainNav {
        padding: 25px 60px;
        -webkit-transition: padding-top 0.2s, padding-bottom 0.2s;
        transition: padding-top 0.2s, padding-bottom 0.2s;
        border: none;
        box-shadow: none;
        background-color: transparent;

        img {
            filter: brightness(100);
        }

        .container {
            max-width: 100%;
        }

        .navbar-expand-xl {
            align-items: center;
        }

        .navbar-brand {
            font-size: 1.75em;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
        }

        .navbar-nav .nav-item .nav-link {
            padding: 1.1em 1em !important;
            align-items: center;
        }

        .nav-item {
            a {
                color: var(--neutral-3) !important;
                text-decoration: none;
                display: inline-block;
                padding: 15px 20px;
                position: relative;
            }

            a:hover {
                color: var(--neutral-3) !important;
            }

            a:after {
                background: none repeat scroll 0 0 transparent;
                bottom: 0;
                content: '';
                display: block;
                height: 2px;
                left: 50%;
                position: absolute;
                background: var(--neutral-3);
                transition: width 0.3s ease 0s, left 0.3s ease 0s;
                width: 0;
            }

            a:hover:after {
                width: 100%;
                left: 0;
            }
        }

        .secondary-button {
            border-color: var(--neutral-3) !important;
            color: var(--neutral-3) !important;
            border-width: 1px;
            font-weight: 450;

            &:hover {
                border-color: var(--primary-1) !important;
                color: var(--primary-1) !important;
            }
        }

        .primary-button {
            background-color: var(--neutral-3);
            color: var(--primary-1);
            transition: none;
            font-weight: 450;

            &:hover {
                background-color: var(--primary-1);
                color: var(--neutral-3) !important;
            }
        }

        &.navbar-shrink {
            margin: 20px 60px;
            padding: 0;
            background-color: var(--neutral-3);
            border-radius: 4px;
            box-shadow: var(--card-shadow);

            a {
                color: var(--neutral-1) !important;

                &:hover {
                    color: var(--primary-1) !important;
                }
            }

            img {
                filter: brightness();
            }

            .primary-button {
                background-color: var(--primary-1);
                color: var(--neutral-3) !important;

                &:hover {
                    color: var(--neutral-3) !important;
                }

                a {
                    color: var(--neutral-3) !important;
                }
            }

            .secondary-button {
                border-color: var(--primary-1) !important;
                color: var(--primary-1) !important;

                a {
                    color: var(--primary-1) !important;
                }

                &:hover {
                    border-color: var(--primary-1) !important;
                    color: var(--primary-1) !important;
                }
            }

            .navbar-brand {
                font-size: 1.25em;
                padding: 12px 0;
            }
        }
    }
}
