@import "../../../../../libs/generics/src/lib/styles/mixins.scss";

.map-popup {
    @include size(100%);
    @include flex(column, flex-start, flex-start);
    cursor: pointer;

    .map-popup-image {
        @include size(100%, 120px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .map-popup-content {
        width: 100%;
        height: calc(100% - 120px);
    }

    h5 {
        font-size: 18px;
        font-weight: 200;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h6 {
        color: var(--neutral-2);
        font-size: 16px;
    }

    p {
        @include elipsis-on-line(3, 54px);
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 0;
    }

    section {
        @include flex(row, space-between);
        margin-top: 8px;
    }
}